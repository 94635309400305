//
//
//
//
//
//
//
//

import GlobalMixin from '../../../shared/GlobalMixins.js'

export default {
  name: 'Loading',
  mixins: [GlobalMixin],
  data () {
    return { }
  },
  methods: {
    myLoop () {

    }
  },
  computed: {
    showProgress () {
      let progress = this.progress*100

      if (progress > 100) progress = 100
      return "width: " + (progress) + "%"
    }
  }
}
