//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../shared/GlobalMixins.js'
import Loading from '../components/GamePlay/Common/Loading'

export default {
  name: 'Login',
  mixins: [GlobalMixin],
  components: {Loading},
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      this.setLoading(true)
      this.setError(null)

      const loggedIn = await this.loginAndStoreToken({
        email: this.email,
        password: this.password
      })

      if (loggedIn) {
        this.setLoggedIn(true);
        await this.$router.push('/');
      } else {
        this.setError('Login failed');
        this.setLoading(false);
        // set focus to login input

      }
    }
  },
  mounted () {
    this.$refs.login.focus();
  },

  created () {

    this.setError(null)
    this.setLoading(false)
    this.setLoggingOut(false)
    if (this.loggedIn) {
      this.$router.push('/')
    }
  }


}
